﻿/*
 *  Document   : _general.scss
 *  Author     : RedStar Template
 *  Description: This scss file for all common style classes
 */

body {
  @include transition(all 0.5s);
  background-color: #f0eff3;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

button,
input,
select,
a {
  outline: none !important;
  font-size: 14px !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

textarea {
  font-size: 14px !important;
}

ol,
ul,
dl {
  padding-left: 0px;
  list-style-type: none;
}

.no-animate {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

section {
  &.content {
    margin: 55px 30px 0 292px;
    min-height: calc(100vh - 76px);
    @include transition(0.5s);
    .content-block {
      padding: 25px 0px 0px 0px;
    }
  }
}

.horizontal-layout {
  section {
    &.content {
      margin: 170px 15px 0 15px;
      float: left;
      width: calc(100% - 30px);
    }
  }
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.msl-1 {
  margin-left: 0.25rem !important;
}
.msl-2 {
  margin-left: 0.5rem !important;
}
.msl-3 {
  margin-left: 1rem !important;
}
.msl-4 {
  margin-left: 1.5rem !important;
}
.msl-5 {
  margin-left: 3rem !important;
}

.msr-1 {
  margin-right: 0.25rem !important;
}
.msr-2 {
  margin-right: 0.5rem !important;
}
.msr-3 {
  margin-right: 1rem !important;
}
.msr-4 {
  margin-right: 1.5rem !important;
}
.msr-5 {
  margin-right: 3rem !important;
}

.psl-1 {
  padding-left: 0.25rem !important;
}
.psl-2 {
  padding-left: 0.5rem !important;
}
.psl-3 {
  padding-left: 1rem !important;
}
.psl-4 {
  padding-left: 1.5rem !important;
}
.psl-5 {
  padding-left: 3rem !important;
}

.psr-1 {
  padding-right: 0.25rem !important;
}
.psr-2 {
  padding-right: 0.5rem !important;
}
.psr-3 {
  padding-right: 1rem !important;
}
.psr-4 {
  padding-right: 1.5rem !important;
}
.psr-5 {
  padding-right: 3rem !important;
}
.cursor-pointer {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 15px;
  height: 15px;
}

table {
  .checkbox {
    [type='checkbox'] + label {
      margin: 0;
      height: 20px;
      padding-left: 20px;
      vertical-align: middle;
    }
  }
}

.loading-img-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -60px 0 20px -20px;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.shadow-style {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.review-img {
  padding-left: 20px;
  padding-top: 5px;
  width: 70px;

  img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }
}

.horizontal-layout {
  .sidebar,
  .nav-left-menu {
    display: none;
  }
}

.bootstrap-notify-container {
  max-width: 320px;
  text-align: center;
}

.map iframe {
  width: 100%;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #eee;
  color: black;
  font-size: 14px;
  font-family: sans-serif, Verdana;
  padding: 10px;
  pointer-events: none;
}

.logo-white {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #000 !important;
  }
}

.logo-black {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #fff !important;
  }
}

.dark {
  .sidemenu-collapse i {
    color: #fff;
  }

  .nav {
    > li {
      > a {
        color: #fff;
      }
    }
  }
}

.light {
  .sidemenu-collapse i {
    color: #0d091d;
  }

  .nav {
    > li {
      > a {
        color: #0d091d;
      }
    }
  }
}

.border-apply {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

input:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

textarea:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

.profile-image img {
  width: 100%;
}

@media screen and (max-width: 1169px) {
  .horizontal-layout {
    .sidebar {
      display: block;
    }

    .top-sidebar {
      display: none;
    }

    section.content {
      margin-top: 100px;
    }
  }
}

.font-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;

  .icon-preview {
    font-size: 1.8rem;
    margin-right: 10px;
    line-height: 1;
    color: #333439;
  }
}

.deshboard-echart-height {
  height: 250px;
}
.pill-style {
  font-size: 17px;
  color: #a9a6a6;
  padding-right: 5px;
}
.pill-timing {
  width: 30%;
}

@media screen and (min-width: 1400px) {
  .boxed-layout .container {
    width: 1370px;
    max-width: 100%;
  }
}

.custom-btn {
  height: 50px;
  /* line-height: 50px !important; */
  text-align: center;
  font-size: 14px !important;
  color: white !important;
  padding: 15px 35px !important;
  display: inline-block;
  transition: all 0.3s ease-out 0s;
}

.custom-primary {
  background-color: #c6a37d !important;
  &:disabled {
    opacity: 0.5; /* Reducir la opacidad para indicar que está deshabilitado */
    cursor: not-allowed; /* Cambiar el cursor a "no permitido" */
  }
}

.custom-secondary {
  background-color: #7dc6a3 !important;
}

.custom-info {
  background-color: #5c5c5c !important;
}

.badge-solid-green {
  color: white !important;
  background-color: rgb(27, 103, 73) !important;
  border: none;
  padding: 10px 12px !important;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
}

.badge-solid-orange {
  color: white !important;
  background-color: rgb(168, 135, 98) !important;
  border: none;
  padding: 10px 12px !important;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
}

.badge-solid-purple {
  color: #fff !important;
  background-color: rgb(92, 92, 92) !important;
  border: none;
  padding: 10px 12px !important;
  font-weight: 500;
  line-height: 1.2;
  width: 100%;
}

@media (max-width: 1300px) {
  .custom-btn {
    padding: 5px 12px !important;
    font-size: 11px !important;
  }
  .materialTableHeader .header-buttons-left .tbl-search-box {
    margin: 0 10px !important;
  }
}

@media (max-width: 600px) {
  .custom-btn {
    padding: 5px 12px !important;
    font-size: 9px !important;
  }
  .mobile-label {
    width: 35% !important;
  }
  .mobile-info {
    width: 65% !important;
  }
  .mat-mdc-card-footer {
    padding: 0 16px !important;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 10px 12px !important;
}

.modalHeader {
  padding: 0px 10px !important;
  margin: 0px !important;
}

.navbar .container-fluid,
.navbar .container {
  box-shadow: 0 4px 24px 0 #bebdc0 !important;
}

.sidebar {
  box-shadow: 0 8px 10px 0 #bebdc0 !important;
}

.menuSidebarIcon {
  padding: 6px 6px 8px;
  margin-right: 8px !important;
  background: #e6e5e9;
  border-radius: 6px;
  position: relative;
}

/* nav.navbar {
  width: calc(100% - 382px) !important;
  left: 322px !important;
} */

.sidebar {
  width: 260px !important;
}

/* section.content {
  margin: 55px 30px 0 352px !important;
} */

.sidebar .menu .list li.active-top .menu-top {
  background-color: #f0eff3 !important;
  span.menuSidebarIcon {
    background-color: #c6a37d !important;
    color: #654927 !important;
  }
  span.hide-menu {
    font-weight: bold;
  }
}

.materialTableHeader {
  background-color: #bebdc0 !important;
  border-color: #bebdc0 !important;
  border-radius: 5px 5px 0px 0px;
}

.card {
  border: 1px solid #bebdc0 !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #3fb53f !important;
}

.card .header {
  background-color: #bebdc0 !important;
  border-color: #bebdc0 !important;
  border-radius: 5px 5px 0px 0px !important;
  h2 {
    color: black !important;
  }
}

.mat-mdc-card {
  border: 1px solid #e6e5e9 !important;
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #a88762;
  --mdc-slider-focus-handle-color: #a88762;
  --mdc-slider-hover-handle-color: #a88762;
  --mdc-slider-active-track-color: #a88762;
  --mdc-slider-inactive-track-color: #a88762;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #a88762;
  --mat-mdc-slider-ripple-color: #a88762;
  --mat-mdc-slider-hover-ripple-color: rgba(168, 135, 98, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(168, 135, 98, 0.2);
}

.user-img {
  align-self: center;
}

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #654927;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #654927;
  --mat-tab-header-active-ripple-color: #654927;
  --mat-tab-header-inactive-ripple-color: #654927;
  --mat-tab-header-inactive-focus-label-text-color: rgba(101, 73, 39, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(101, 73, 39, 0.6);
  --mat-tab-header-active-focus-label-text-color: #654927;
  --mat-tab-header-active-hover-label-text-color: #654927;
  --mat-tab-header-active-focus-indicator-color: #654927;
  --mat-tab-header-active-hover-indicator-color: #654927;
}

.materialTableHeader .right {
  flex: 15% !important;
}

.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
